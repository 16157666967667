<template>
  <div class="contents register toy">
    <div class="title flexB">
      <h2>{{ editMode ? "작물재배정보 수정" : "작물재배정보 등록" }}</h2>
    </div>
    <div class="box one">
      <div class="mh">
        <div class="flexL">
          <p class="bold">
            카테고리
            <span>*</span>
          </p>
          <select class="searchCondition" v-model="category">
            <option value disabled>선택</option>
            <option value="딸기">딸기</option>
            <option value="토마토">토마토</option>
            <option value="사과">사과</option>
            <option value="고추">고추</option>
            <option value="포도">포도</option>
            <option value="레드향">레드향</option>
            <option value="상추">상추</option>
          </select>
        </div>

        <div class="flexL">
          <p class="bold">
            제목
            <span>*</span>
          </p>
          <input type="text" v-model="title" />
        </div>

        <div class="flexL">
          <p class="bold">
            상세 내용
            <span>*</span>
          </p>
          <textarea v-model="content_1" />
        </div>
        <!-- <div class="flexL">
          <p class="bold">상세 내용 2</p>
          <textarea v-model="content_2" />
        </div>
        <div class="flexL">
          <p class="bold">상세 내용 3</p>
          <textarea v-model="content_3" />
        </div> -->

        <div class="flexL">
          <p class="bold">
            이미지
            <br />
          </p>
          <div>
            <div class="flexB">
              <div class="filebox">
                <label for="images">찾아보기</label>
                <input type="file" ref="images" id="images" @change="sendFile()" accept="image/*" multiple />
              </div>
            </div>
            <div class="flex" v-if="images && images.length > 0">
              <div v-for="(image, i) in images" :key="i" class="imgWrap">
                <img :src="image" alt="이미지" id="img" />
                <a class="cancel" @click="handleDeleteFile(image)">
                  <img src="@/assets/images/icon_svg/ic_delete_wh.svg" alt="icon" />
                </a>
              </div>
            </div>
          </div>
          <label>
            <input type="checkbox" id="fcmCheck" v-model="fcmCheck" />
            모든 사용자에게 푸쉬 메세지를 자동 발송합니다.
          </label>
        </div>
      </div>
      <div class="buttonWrap">
        <!-- <router-link to="/admin/notices">목록</router-link> -->
        <button class="basic" @click="submit">
          {{ $route.query.id ? "수정" : "등록" }}
        </button>
        <button v-if="editMode" class="line" @click="handleDelete">삭제</button>
      </div>
    </div>
  </div>
</template>
<style scoped>
#fcmCheck {
  -webkit-appearance: auto;
}
</style>
<script>
import moment from "moment";
import { uploadFile, deleteFile, deletePostFile } from "@/api/file";
import { createCultureInfo, upadateCultureInfoDetails, fetchCultureInfoDetails, removeCultureInfo, createNoticeFcm } from "@/api/admin";
export default {
  data() {
    return {
      editMode: false,
      moment: moment,
      category: "",
      title: "",
      content_1: "",
      // content_2: "",
      // content_3: "",
      images: [],
      file: "",
      cultureInfoId: "",
      fcmCheck: false,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "4");
  },
  mounted() {
    if (this.$route.query.id) {
      this.editMode = true;
      this.cultureInfoId = this.$route.query.id;
      this.getCultureInfo();
    }
  },
  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
      // this.openAlert("전화번호");
      //   return;
    },
    getCultureInfo() {
      fetchCultureInfoDetails(this.cultureInfoId).then((res) => {
        if (res.data.status == 200) {
          this.category = res.data.data.category;
          this.title = res.data.data.title;
          this.content_1 = res.data.data.content_1;

          this.images = res.data.data.images;
        } else {
          this.openAlert(res.data.message);
          return;
        }
      });
    },
    sendFile() {
      this.file = this.$refs.images.files[0];
      if (this.file == undefined || this.file == "") {
        return;
      }

      let formdata = new FormData();
      formdata.append("file", this.file);
      uploadFile(formdata).then((res) => {
        if (res.status == 200) {
          this.file = "";
          this.images.push(res.data.url);
        } else {
          this.openAlert("서버오류 입니다. 다시 파일을 업로드 해주세요.");
          return;
        }
      });
    },
    handleDeleteFile(url) {
      this.$confirm("이미지를 삭제 하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        if (this.editMode) {
          deletePostFile(this.cultureInfoId, url).then((res) => {
            if (res.data.status == 200) {
              this.images.splice(this.images.indexOf(url), 1);
            } else {
              this.openAlert(res.data.message);
            }
          });
        } else {
          deleteFile(url).then((res) => {
            if (res.data.status == 200) {
              this.images.splice(this.images.indexOf(url), 1);
            } else {
              this.openAlert(res.data.message);
            }
          });
        }
      });
    },
    handleDelete() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        removeCultureInfo(this.cultureInfoId).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: "작물재배정보가 삭제 되었습니다.",
              type: "success",
            });
            this.$router.push("/admin/cultureInfos");
          }
        });
      });
    },
    submit() {
      if (this.category == "") {
        this.openAlert("카테고리를 선택해 주세요.");
        return;
      } else if (this.title == "") {
        this.openAlert("제목을 입력해 주세요.");
        return;
      } else if (this.content_1 == "") {
        this.openAlert("상세 내용1을 입력해 주세요.");
        return;
      }
      let data = {
        category: this.category,
        title: this.title,
        content_1: this.content_1,

        images: this.images,
        thumbnail: this.thumbnail,
        cultureInfoId: this.cultureInfoId,
      };
      if (this.editMode) {
        upadateCultureInfoDetails(data).then((res) => {
          if (res.data.status == 200) {
            this.openAlert("작물재배정보가 수정 되었습니다.");
            this.$router.push("/admin/cultureInfos");
          } else {
            this.openAlert(res.data.message);
          }
        });
      } else {
        createCultureInfo(data).then((res) => {
          if (res.data.status == 200) {
            this.openAlert("작물재배정보 등록이 완료 되었습니다.");
            this.$router.push("/admin/cultureInfos");
          } else {
            this.openAlert(res.data.message);
          }
        });
      }
      if (this.fcmCheck) {
        data.url = "cultureInfo";
        createNoticeFcm(data).then((res) => {
          if (res.data.status == 200) {
            console.log("공지사항 등록이 완료 되었습니다.");
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      }
    },
  },
};
</script>
